<template>
  <div class="position-relative">
    <Button
      classes="btn-default bg-gradient-primary"
      :class_div="`d-flex justify-end mx-4 mt-5`"
      @click="handleSubmit"
      :title="$t('project.save project')"
      :loader="loading"
    ></Button>
    <loading v-if="card.loader"></loading>
    <CardForm class="mt-2" :col="6">
      <template #card-section-2>
        <v-col md="6" cols="12" class="mb-6">
          <v-card class="card-shadow border-radius-xl">
            <div class="pa-6 border">
              <h5 class="text-h5 text-typo font-weight-normal">
                {{ $t("project.work time info") }}
              </h5>
            </div>

            <div class="px-6 border py-6">
              <v-form
                ref="form_work"
                lazy-validation
                v-model="valid"
                class="mt-4 pt-2"
              >
                <v-row>
                  <v-col cols="12" v-if="work_id">
                    <h4 class="text-sm text-typo">
                      {{ $t("edit") + "#" + work_id }}
                    </h4>
                  </v-col>
                  <v-col
                    class="py-0"
                    :key="'col-' + ind"
                    v-for="(item, ind) in style_work_count_time"
                    :cols="item.col ? item.col : '6'"
                  >
                    <template
                      v-if="
                        item.hasOwnProperty('visible') ? item.visible : true
                      "
                    >
                      <!-- {{ item.visible }} -->
                      <Input :item="item"></Input>
                    </template>
                  </v-col>
                  <v-col class="pt-0" :class="classLang">
                    <Button
                      @click="addWorkTime()"
                      :title="$t('general.add')"
                    ></Button>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card>
          <v-card v-if="works.length" class="card-shadow border-radius-xl mt-9">
            <div class="pa-4">
              <item-project-component :data="works"></item-project-component>
            </div>
          </v-card>
        </v-col>
      </template>
    </CardForm>
  </div>
</template>
  <script>
import CardForm from "../Components/CardForm";
import Input from "../Components/Input";
import Button from "../Components/Button";
import Loading from "../Components/Loading";
import projectService from "../../../services/project.service";
import {
  isFormValidate,
  isValueNotEmpty,
  isDropDownComponent,
} from "@/helper/General";
import { style_work } from "./style";
import { mapState } from "vuex";
import ItemProjectComponent from "./item_project_component.vue";
export default {
  name: "New-Project",
  components: {
    CardForm,
    Input,
    Loading,
    Button,
    ItemProjectComponent,
  },
  data() {
    return {
      work_id: null,
      valid: true,
      loading: false,
      works: [],
      style_work_count_time: [],
      style_form: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("project.project_name"),
          placeholder: "name",
          error: null,
          value_text: "project_name",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "number",
          label: this.$i18n.t("project.price_of_project"),
          placeholder: "60",
          error: null,
          value_text: "price_of_project",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "number",
          label: this.$i18n.t("project.number_of_hour"),
          placeholder: "100",
          error: null,
          value_text: "number_of_hour",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "number",
          label: this.$i18n.t("project.number_of_employee"),
          placeholder: "20",
          error: null,
          value_text: "number_of_employee",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "autocomplete",
          label: this.$i18n.t("project.contractor"),
          value_text: "contractor_id",
          items: [],
          //   title_select: "name",
          error: null,
        },
        {
          col: "12",
          type: "autocomplete",
          label: this.$i18n.t("project.status"),
          value_text: "status_id",
          items: [],
          title_select: "status_lang",
          error: null,
        },
        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("project.address_project"),
          error: null,
          value_text: "address_project",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
      card: {
        title: "",
        loader: false,
        sub_title: this.$i18n.t("project.Project info"),
      },
    };
  },
  computed: {
    ...mapState("project", ["project"]),
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      const formData = new FormData();
      for (var i = 0; i < this.works.length; i++) {
        const val = this.works[i];
        formData.append(`works_name[${i}]`, val.name);
        formData.append(`count_employee[${i}]`, val.count_employee);
        formData.append(`start_time[${i}]`, val.start_time);
        formData.append(`end_time[${i}]`, val.end_time);
      }

      this.style_form.map(function (item) {
        if (isValueNotEmpty(item.value)) {
          if (isDropDownComponent(item.value)) {
            var value = item.value;
            for (var i = 0; i < value.length; i++) {
              const val = value[i];
              formData.append(`${item.value_text}[ ${i} ]`, val);
            }
          } else {
            formData.append(item.value_text, item.value);
          }
        }
      });
      return projectService.store(formData).then(
        (response) => {
          this.loading = false;
          this.$router.push({
            name: "Projects",
            params: {
              message: {
                msg: response.data.message,
                type: "Success",
              },
            },
          });
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.$router.push({
            name: "Projects",
            params: {
              message: {
                msg: error.response.data.message,
                type: "Danger",
              },
            },
          });
        }
      );
    },
    // edit_work(item) {
    //   console.log(item);
    //   this.work_id = item.id
    //   this.style_work_count_time[0].value = item.count_employee;
    //   this.style_work_count_time[1].value = item.start_time;
    //   this.style_work_count_time[2].value = item.end_time;
    // },

    addWorkTime() {
      if (isFormValidate(this.$refs.form_work)) {
        var item = {
          name: "",
          count_employee: "",
          start_time: "",
          end_time: "",
        };
        this.style_work_count_time.forEach(function (v) {
          item[v.value_text] = v.value;
        });
        console.log(item);
        this.works.push(item);
        this.$refs.form_work.reset();
      }
    },
    set_data() {
      this.$store.commit("SET_COLLECTION", "project");
      this.$store.commit("SET_FUNCTION", "add");
      this.$store.commit("SET_CARD", this.card);
      this.style_work_count_time = style_work;
    },
    get_data() {
      this.card.loader = true;
      return projectService.create().then(
        (response) => {
          this.card.loader = false;
          //   this.works = response.data.works;
          this.style_form[4].items = response.data.contractors;
          this.style_form[5].items = response.data.status;
          this.$store.commit("form/SET_FORM_STYLE", this.style_form);
        },
        (error) => {
          console.log(error);
          this.card.loader = false;
          var message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
          this.$router.push({
            name: "Projects",
            params: { message: message },
          });
        }
      );
    },
  },
  mounted() {
    this.set_data();
    this.get_data();
    this.$store.commit("form/SET_HIDE_BTN", true);
    document.title = this.$i18n.t("CreateProject");
  },
};
</script>
  